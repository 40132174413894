<template>
  <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
    <div class="small-box bg-olive pointer" v-on:click="$router.push('/tasks/funnel/detailing')">
      <div class="inner">
        <h3>{{ detailing_tasks_count }}</h3>
        <h5>Детейлинг</h5>
      </div>
      <div class="icon">
        <i class="fas fa-car-on"></i>
      </div>
      <a class="small-box-footer" title="Перейти" style="padding-top: 6px; padding-bottom: 6px;">
        <i class="fa font-scale fa-arrow-circle-right"></i>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'exchange-tasks-box',

  props: {
    detailing_tasks_count: {
      type: Number,
      required: true,
      default: 0,
    },
  }
}
</script>
